<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" @current-change="TableCurrentChange" ref="table">
            <!-- <el-button type="primary" @click="Editor()">新增</el-button> -->
        </ATable>
    </div>
</template>


<script>
import ATable from '@/components/table/index'

import { list } from '@/api/email'
import { provide, ref } from 'vue'

export default {
    components: {ATable},
    setup () {
        const tableUrl = ref(list)
        const columns = ref([
            {prop: 'fishCount', label: '小鱼干数量'},
            {prop: 'financialCount', label: '财商币数量'},
            {prop: 'cardName', label: '卡片'},
            {prop: 'status', label: '状态', render: (text) => text == 1 ? '未领取' : '领取'},
        ])

        const searchFormItems = ref([
        ])

        let editorForm = ref({})
        let employeeVisible = ref(false)
        let table = ref(null)
        provide('employeeVisible', employeeVisible)
        provide('editorForm', editorForm)

        const methods = {
            Editor (row = {}) {
                if (row.id) {
                    editorForm.value = {name: row.name, pass: row.pass, phone: row.phone, id: row.id}
                } else {
                    editorForm.value = {name: '', pass: '', phone: ''}
                }
                employeeVisible.value = !employeeVisible.value
            },
            OperateButtonCallBack ({row, fun}) {
                methods[fun](row)
            },
            EmployeeEditSubmit () {
                employeeVisible.value = false
                table.value.SearchSubmit()
            },
            TableCurrentChange () {
                
            }
        }


        return {
            table,
            columns,
            tableUrl,
            searchFormItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>